import React, {Component} from 'react'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import './Loading.css'
export default class App extends Component {
  render() {
    return(
      <div className="loader-container">
        <Loader 
          type="ThreeDots" 
          color="#005083" 
          height={80} 
          width={550} 
          className="loader"/>
      </div>
    );
  }
 }