import React, {Component} from 'react';
import './SignIn.css';
import {Link} from 'react-router-dom';
import {API_URL, LOGIN_URL, CLIENT_ID, CLIENT_SECRET} from '../BaseUrlApi/Url'
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import { ValidationForm, TextInput} from "react-bootstrap4-form-validation";
import axios from 'axios'

class SignIn extends Component{
  constructor(props){
    super(props);
    this.formRef = React.createRef();
    this.state = {
        immediate:true,
        setFocusOnError:true,
        clearInputOnReset:false,
        isLoading: false
    }
}
  handleSubmit = (e, formData, inputs) => {
      e.preventDefault();
      console.log(formData);
      let loginData = {
        username : formData.username,
        password : formData.password,
        scope : '*',
        client_secret : CLIENT_SECRET,
        client_id : CLIENT_ID,
        grant_type : 'password'
      }

      this.postData(loginData)
    
      // this.props.history.push('/table');
      // alert(JSON.stringify(formData, null, 2));
  }

  alertErrorLogin = () => {
    swal({
      title: "Login gagal!",
      text: "username atau password yang kamu masukkan salah, silahkan login kembali dengan akun yang benar",
      type: "error",
      buttons:["", "Oke"]
    })
  }

  postData = async(loginData) =>{
    try{
    this.setState({isLoading: true})
    let responseLogin = await axios.post(LOGIN_URL, loginData)
    console.log(responseLogin)
    localStorage.setItem('access_token', responseLogin.data.access_token)
    window.location.href = '/'
    } catch(e){
      console.log(e)
      this.setState({isLoading: false})
      this.alertErrorLogin()
    }
  }
  handleErrorSubmit = (e,formData, errorInputs) => {
      console.log(e,formData, errorInputs);
  }
  render(){
  return (
    <div className="login-app">
      <div className="login-form">
        <div className="title-login">
          <p className="p1">TAKALAR</p>
          <p className="p2">SEHAT</p>
        </div>

        <ValidationForm 
          onSubmit={this.handleSubmit.bind(this)} 
          onErrorSubmit={this.handleErrorSubmit}
          ref={this.formRef}
          immediate={this.state.immediate}
          setFocusOnError={this.state.setFocusOnError}
          // defaultErrorMessage={{ required: "Please enter something."}}  
          className="form-group-login">
          <div className="username-login">
            <div className="control">
              <TextInput
                name="username" 
                id="email" 
                type="text"
                placeholder="Username"
                successMessage="Looks good!"
                errorMessage="Please enter something"
                required
              />
            </div>
          </div>

          <div className="password-login">
            <div className="control">
              <TextInput
                type="password"
                name="password"
                placeholder="Password"
                errorMessage="Please enter something"
                required
              />
            </div>
          </div>

          <div className="text-login">
            <div className="lupa-sandi">
              <p>Lupa kata sandi ?</p>
            </div>
            <div className="login">
                <button type="submit" className={this.state.isLoading === false ? "button is-block is-info is-fullwidth" : "d-none"}>
                  Log In
                </button>
                <button type="button" disabled className={this.state.isLoading === true ? "button is-block is-info is-fullwidth" : "d-none"}>
                  Loading...
                </button>
            </div>
            <div className="regis">
              <Link to="/signup">
                <p>
                  Belum memiliki akun ?{" "}
                  <span className="text-bold"> Sign Up </span>
                </p>
              </Link>
            </div>
          </div>
        </ValidationForm>
      </div>
    </div>
  );
}
}

export default withRouter(SignIn);
