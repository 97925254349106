import React, {Component} from 'react'
import axios from 'axios'
import {Navbar} from 'react-bootstrap'
import { Link, withRouter } from 'react-router-dom'
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Dropdown} from 'react-bootstrap';
import swal from 'sweetalert';
import { ValidationForm, TextInput} from "react-bootstrap4-form-validation"
import { faChevronLeft,  faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import Map from '../MapsLocation/MapsLocation'
import './FormHousehold.css';
import {HEADERS, API_URL} from '../BaseUrlApi/Url'

const headers = {
  'Authorization' : `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type' : 'application/json'
}
class FormHousehold extends Component{
  constructor(props){
    super(props);
    this.formRef = React.createRef();
    this.state = {
        immediate:true,
        setFocusOnError:true,
        clearInputOnReset:false,
        province:'',
        city:'',
        district:'',
        village:'',
        hamlet:'',
        neighborhood_association:'',
        citizens_association:'',
        latitude:'',
        longitude:'',
        provinsi:[],
        cities:[],
        districts:[],
        villages:[],
        hamlets:[],
        openMaps:false,
        new_latitude:'',
        new_longitude:'',
        data_form1:{}
        
    }
  }

  
  componentDidMount(){
    
    if(navigator && navigator.geolocation){
      navigator.geolocation.getCurrentPosition(position => {
        this.setState({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        })
      })
    }
    this.getDataProvinces()

  }
  handleSubmit = (e, formData, inputs) => {
      e.preventDefault();
      console.log(formData);
      this.props.history.push('/form2');
      // alert(JSON.stringify(formData, null, 2));
  }
  handleErrorSubmit = (e,formData, errorInputs) => {
      console.log(e,formData, errorInputs);
  }


  // THIS FUNCTION TO GET PROVINCES DATA
  getDataProvinces = async(e) =>{
    let provincesMap = await axios.get(`${API_URL}/regions/provinces`, {headers})
    let getProvince = await provincesMap.data.provinces.map(province => (
      {
        value:province.id,
        label:province.name
      }
      ))
    await this.setState({
      provinsi:getProvince
    })
    
  }

  // THIS FUNCTION TO CHANGE THE STATE OF PROVINCE
  getProvince = async(e) =>{
    await this.setState({
      province:e.target.value
    })
    console.log(this.state.province)
    this.getDataCities()
  }

  // THIS FUNCTION TO GET CITIES DATA
  getDataCities = async(e) =>{
    let provinceId = this.state.province;
    let citiesMap = await axios.get(`${API_URL}/regions/provinces?id=${provinceId}`, {headers})
    let getCity = await citiesMap.data.provinces.cities.map(city => (
      {
        value:city.id,
        label:city.name
      }
    ))
    await this.setState({
      cities:getCity
    })
  }

  // THIS FUNCTION TO CHANGE THE STATE OF CITY
  getCitie = async(e) =>{
    await this.setState({
      city:e.target.value
    })
    console.log(this.state.city)
    this.getDataDistricts()
  }

  // THIS FUNCTION TO GET DISTRICTS DATA
  getDataDistricts = async(e) =>{
    let cityId = this.state.city;
    let districtsMap = await axios.get(`${API_URL}/regions/cities?id=${cityId}`, {headers})
    let getDistrict = await districtsMap.data.cities.districts.map(district => (
      {
        value:district.id,
        label:district.name
      }
      ))
    this.setState({
      districts:getDistrict
    })
  }

  // THIS FUNCTION TO CHANGE THE STATE OF DISTRICT
  getDistrict = async(e) =>{
    await this.setState({
      district:e.target.value
    })
    this.getDataVillages()
  }

  // THIS FUNCTION TO GET VILLAGES DATA
  getDataVillages = async(e) =>{
    let districtId = this.state.district;
    let villagesMap = await axios.get(`${API_URL}/regions/districts?id=${districtId}`, {headers})
    let getVillage = await villagesMap.data.districts.villages.map(village => (
      {
        value:village.id,
        label:village.name
      }
      ))
    this.setState({
      villages:getVillage
    })
  }

  // THIS FUNCTION TO CHANGE THE STATE OF VILLAGE
  getVillage = async(e) =>{
    await this.setState({
      village:e.target.value
    })
    console.log(this.state.village)
    this.getDataHamlets()
  }

    // THIS FUNCTION TO GET VILLAGES DATA
    getDataHamlets = async(e) =>{
      let villageId = this.state.village;
      let hamletsMap = await axios.get(`${API_URL}/hamlets?village_id=${villageId}`, {headers})
      let getHamlet = await hamletsMap.data.hamlets.map(hamlet => (
        {
          value:hamlet.id,
          label:hamlet.name
        }
        ))
      this.setState({
        hamlets:getHamlet
      })
    }
    // THIS FUNCTION TO CHANGE THE STATE OF VILLAGE
    getHamlet = async(e) =>{
      await this.setState({
        hamlet:e.target.value
      })
    }

  openMaps = async(e) =>{
    this.setState({
      openMaps:!this.state.openMaps
    })
    console.log(this.state.openMaps)
  }

  
  getData1 = async(e) =>{
    e.preventDefault();
    const getHousehold = {
      province_id : this.state.province,
      city_id :this.state.city,
      district_id :this.state.district,
      village_id :this.state.village,
      hamlet_id :this.state.hamlet,
      neighborhood_association :this.state.neighborhood_association,
      citizens_association :this.state.citizens_association,
      latitude :this.state.latitude,
      longitude :this.state.longitude,
    }

    this.setState({
      data_form1:getHousehold
    })
    console.log(this.state.data_form1)

    localStorage.setItem('province_id', this.state.province)
    localStorage.setItem('city_id', this.state.city)
    localStorage.setItem('district_id', this.state.district)
    localStorage.setItem('village_id', this.state.village)
    localStorage.setItem('hamlet_id', this.state.hamlet)
    localStorage.setItem('neighborhood_association', this.state.neighborhood_association)
    localStorage.setItem('citizens_association', this.state.citizens_association)
    localStorage.setItem('latitude', this.state.latitude)
    localStorage.setItem('longitude', this.state.longitude)

    this.props.history.push('/household-form2')

  }

  logOut = async() =>{
    const headers = {
      'Authorization' : `Bearer ${localStorage.getItem('access_token')}`,
      'Content-Type' : 'application/json'
    }

    await axios.post(`${API_URL}/auth/logout`, [], {headers})
    localStorage.clear()
    window.location.href = '/login'
  }

  logOutAlert(){
    console.log('clicks')
    // return(<SweetAlert title="Here's a message!" onConfirm={this.onConfirm} />)
    swal({
      title: "LOG OUT",
      text: "Apakah anda yakin ingin Log Out dari aplikasi ini ?",
      buttons: ['Tidak', 'Ya'],
      dangerMode: true,
    })
    .then((removeData) => {
      if (removeData) {
        const logOutResponse = async() =>{
        const headers = {
          'Authorization' : `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type' : 'application/json'
        }
  
        await axios.post(`${API_URL}/auth/logout`, [], {headers})
        localStorage.clear()
        window.location.href = '/login'
      }
      logOutResponse()
      } else {
        return 
      }
    }); 
  }
  render(){
    return(
      <div>
        <Navbar className="navbar-kk">
          <Link to="/">
            <FontAwesomeIcon icon={faChevronLeft} className="left-icon" />
          </Link>
          <h5 className="brand-kk" href="#home">Pengenalan Tempat</h5>
          <div className="household-dropdown">
            <Dropdown>
              <Dropdown.Toggle>
                <FontAwesomeIcon icon={faEllipsisV} className="menu-icon" />
              </Dropdown.Toggle>
              <Dropdown.Menu style={{
                  top:'0rem !important',
                  left : '2rem !important'
                }}>
                <Dropdown.Item href="/" className="item-list">User</Dropdown.Item>
                <Dropdown.Item 
                  href="#" 
                  className="item-list" 
                  onClick={this.logOutAlert} >Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Navbar>
        <ValidationForm onSubmit={this.getData1} 
                        onErrorSubmit={this.handleErrorSubmit}
                        ref={this.formRef}
                        immediate={this.state.immediate}
                        setFocusOnError={this.state.setFocusOnError}
                        >
        <div className="form-page-content">
          <div className="form-page-group">
            <label htmlFor="">Provinsi</label>
            <select onChange={this.getProvince}>
              <option>--- Pilih Provinsi ---</option>
              {this.state.provinsi.map(province => 
                <option key={province.value} value={province.value}>{province.label}</option>
              )}
            </select>
          </div>
          <div className="form-page-group">
            <label htmlFor="">Kabupaten/Kota</label>
            <select disabled={this.state.cities.length === 0 ? true : false}
                    onChange={this.getCitie}>
              <option disabled selected>--- Pilih Kabupaten/Kota ---</option>
              {this.state.cities.map(city => 
                <option key={city.value} value={city.value}>{city.label}</option>
              )}
            </select>
          </div>
          <div className="form-page-group">
            <label htmlFor="">Kecamatan</label>
            <select disabled={this.state.districts.length === 0 ? true : false}
                    onChange={this.getDistrict}>
              <option disabled selected>--- Pilih Kecamatan ---</option>
              {this.state.districts.map(district => 
                <option key={district.value} value={district.value}>{district.label}</option>
              )}
            </select>
          </div>
          <div className="form-page-group">
            <label htmlFor="">Desa/Kelurahan</label>
            <select disabled={this.state.villages.length === 0 ? true : false}
                    onChange={this.getVillage}>
              <option disabled selected>--- Pilih Desa/Kelurahan ---</option>
              {this.state.villages.map(village => 
                <option key={village.value} value={village.value}>{village.label}</option>
              )}
            </select>
          </div>
          <div className="form-page-group">
            <label htmlFor="">Dusun</label>
            <select 
                    onChange={this.getHamlet}>
              <option disabled selected>--- Pilih Dusun ---</option>
              {this.state.hamlets.map(hamlet => 
                <option key={hamlet.value} value={hamlet.value}>{hamlet.label}</option>
              )}
            </select>
          </div>
            <div className="label">
              <label htmlFor="">RT/RW</label>
            </div>
          <div className="form-page-wrapper">
            <div className="input-control">
              <TextInput 
                type="number" 
                name="neighborhood_association"
                className="form-control col-sm-11 input-rt input-double-style"
                placeholder="RT"  
                successMessage="Looks good!"
                errorMessage="Please enter something"
                onChange={ async(e)=>{
                  await this.setState({
                    neighborhood_association:e.target.value
                  })
                  console.log(this.state.neighborhood_association)
                }}
                required
              />
            </div>
            <div>
              <TextInput 
                type="number" 
                name="citizens_association"
                className="form-control col-sm-11 input-rw"
                placeholder="RW"  
                successMessage="Looks good!"
                errorMessage="Please enter something"
                required
                onChange={ async(e)=>{
                  await this.setState({
                    citizens_association:e.target.value
                  })
                  console.log(this.state.citizens_association)
                }}
              />
            </div>
          </div>
          <div className="label">
            <label htmlFor="">GPS Koordinat</label>
          </div>
          <div className="button-wrapper">
            <div className="maps-button" onClick={this.openMaps}>Maps</div>
          </div>
          <div className="open-maps">
              {this.state.openMaps === true ?  
              <div>
                <Map
                  google={this.props.google}
                  center={{lat: this.state.latitude, lng: this.state.longitude}}
                  height='500px'
                  zoom={15}
                /> 
                <div className="maps-wrapper">
                <div>
                    <button type="button" className="set-location-button" onClick={()=>{
                      this.setState({
                          new_latitude : localStorage.getItem('latitude'),
                          new_longitude : localStorage.getItem('longitude')
                      })
                      }}>Set Location</button>
                  </div>
                  <div className="input-control">
                    <TextInput 
                      type="text" 
                      name="latitude"
                      className="form-control col-sm-11 latitude-input"
                      placeholder="Latitude"
                      value={this.state.new_latitude}
                      errorMessage="Please enter something"
                      required
                      readOnly
                    />
                  </div>
                  <div className="input-control">
                    <TextInput 
                      type="text" 
                      name="longitude"
                      className="form-control col-sm-11 longitude-input"
                      placeholder="Longitude"
                      value={this.state.new_longitude}
                      errorMessage="Please enter something"
                      required
                      readOnly
                    />
                  </div>
                  
                </div>
              </div>

                : 
              ''              
              }
          </div>
          
          <div className="form-group">
              {/* <Link to="/household-form2"> */}
                <button type="submit" className="btn primary form2-button" >NEXT</button>
              {/* </Link> */}
          </div>
        </div>
        </ValidationForm>
      </div>
    );
  }
}

export default withRouter(FormHousehold);

