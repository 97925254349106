import React,{Component} from 'react';
import axios from 'axios'; 
import {Link} from 'react-router-dom';
import './Table.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {HEADERS, API_URL} from '../BaseUrlApi/Url'
import swal from 'sweetalert';
import NavbarTable from './NavbarTable/NavbarTable'

const { SearchBar } = Search;
function nameController(cell, row, rowIndex, formatExtraData) { 
  if(row.head === null){
    return row.head = <p style={{color:'red'}}>Belum Diisi</p>
  }else{
    return row.head.name
  }

} 
function bioFormatter(cell, row, rowIndex, formatExtraData) { 
  return ( 
      <Link to={`/biodatakeluarga/${row.id}`}>
        <button onClick={()=>{console.log(row.id)}} className="survei-button">Edit</button> 
      </Link>
); } 
  class Bismillah extends Component{
    state = {
      isLoaded: false,
      name:[],
      id:[],
      products:[],
      columns:[
        {
          dataField: 'family_card_number',
          text: 'NIK'
        },
        {
          dataField: 'head.name',
          text: 'Nama',
          style:{textAlign:'left', paddingLeft:'4rem'},
          formatter: nameController,
        },
        {
          dataField: 'Aksi',
          text: 'Aksi',
          formatter:bioFormatter
        }
      ],
  }  
    componentDidMount(){
      this.getDataTable()
    }
    // FUNCTION TO GET ALL DATA OF HOUSEHOLDS
    getDataTable = async(e) => {
      try {
        this.setState({isLoaded: true})
        console.log(HEADERS)
        const headers = {
          'Authorization' : `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type' : 'application/json'
        }
  
        let getHouseholds = await axios.get(`${API_URL}/households`, {headers})
        this.setState({
          products:getHouseholds.data.households
        })
        this.setState({isLoaded: false})
      } catch(e) {
        console.log(e)
        this.setState({isLoaded: false})
      }
      
    } 

    logOut = async() =>{
      const headers = {
        'Authorization' : `Bearer ${localStorage.getItem('access_token')}`,
        'Content-Type' : 'application/json'
      }

      await axios.post(`${API_URL}/auth/logout`, [], {headers})
      localStorage.clear()
      window.location.href = '/login'
    }

    logOutAlert(){
      console.log('clicks')
      // return(<SweetAlert title="Here's a message!" onConfirm={this.onConfirm} />)
      swal({
        title: "LOG OUT",
        text: "Apakah anda yakin ingin Log Out dari aplikasi ini ?",
        buttons: ['Tidak', 'Ya'],
        dangerMode: true,
      })
      .then((removeData) => {
        if (removeData) {
          const logOutResponse = async() =>{
          const headers = {
            'Authorization' : `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type' : 'application/json'
          }
    
          await axios.post(`${API_URL}/auth/logout`, [], {headers})
          localStorage.clear()
          window.location.href = '/login'
        }
        logOutResponse()
        } else {
          return 
        }
      }); 
    }

   
    render(){
      const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
          Showing { from } to { to } of { size } Results
        </span>
      );
      const options = {
        paginationSize: 4,
        pageStartIndex: 0,
        // alwaysShowAllBtns: true, // Always show next and previous button
        // withFirstAndLast: false, // Hide the going to First and Last page button
        // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        
        prePageText: 'Back',
        nextPageText: 'Next',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [
        {
          text: '15', value: 15
        },
        {
          text: '30', value: 30
        },
        {
          text: '50', value: 50
        },
        {
          text: 'All', value: this.state.products.length
        }] // A numeric array is also available. the purpose of above example is custom the text
      };
      return(
        <div className="table-body">
          <div className={this.state.isLoaded === true ? "loading" : "d-none"}>
            <div className="loaders"></div>
              <h2 className="loading-soal text-center">Sedang memuat...</h2>
          </div>
          <NavbarTable/>
          <ToolkitProvider
            keyField="id"
            data={this.state.products }
            columns={this.state.columns }
            search
            className="custom-search-table"
          >
            {
              props => (
                <div>
                  <SearchBar { ...props.searchProps } />
                  <hr />
                  <BootstrapTable 
                    striped
                    borderless
                    pagination={ paginationFactory(options) } 
                    { ...props.baseProps }
                  />
                  
                  <Link to="/household-form">
                    <button type="button" className="button-tambah">
                      <div className="button-text">+</div>
                    </button>
                  </Link>
                </div>
              )
            }
          </ToolkitProvider>
      </div>
    )
  }
}

export default Bismillah