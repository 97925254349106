import React, {Component} from 'react'
import './MultiChoice.css'
class MultiChoice extends Component{
  constructor(props){
    super(props)
    this.state = {
      questions:[],
      multi_choices:[],
      checked:false
    }
  }
  componentDidMount(){
    // console.log(this.props.multi)
  }
  getMultiChoices = async(e) =>{
      let newMultiChoices = {
        question_id : parseInt(e.target.name),
        choice_id : parseInt(e.target.value),
        checked : e.target.checked
      }
      this.props.handler(newMultiChoices)
  }

  fillCheckbox = choice_id =>{
    let checked = false;
    this.props.multi.map(multi_choice => {
      if(multi_choice.choice_id === choice_id){
        checked=true
      }
    })
    return checked;
  }
  render(){
    return(
        <div className="multi-question" >
        <p className="question-text">{this.props.questions.question}</p>
        {this.props.questions.choices.map(choice => (
          <div className="checkbox-button multi-wrapper">
            <div className="checkbox-style">
              <input 
                checked={!!this.fillCheckbox(choice.id)}
                onClick={this.toggleClick} 
                type="checkbox" 
                className="checkbox-button__input" 
                id={choice.id}
                name={this.props.questions.id}
                value={choice.id}
                onChange={this.getMultiChoices}
                />
              <span className="checkbox-button__control"></span>
              <label htmlFor={choice.id} className="choice-title checkbox-button__label">{choice.answer}</label>
            </div>
          </div>
        ))}
      </div>
    )
  }
}

export default MultiChoice