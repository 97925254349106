import React, {Component} from 'react'
import './NumericChoice.css'

class NumericChoice extends Component{
  constructor(props){
    super(props)
    this.state = {
      questions:[],
      numerical_input:0,
      show:false,
      questionList:[],
      radio:true,
      answer:{},
      answers:[],
      choices:[],
      essays:[],
      numerical_answers:[],
      string_answers:[],
      date_answers:[],
      numeric_answers:[]
    }
  }
  async componentDidMount(){
    let questions = []
    questions.push(this.props.questions)
    await this.setState({
      questionList:questions
    })
    this.fillNumerical()
  }
    // INCREMENT FUNCTION FOR NUMERICAL QUESTION
    incrementItem = async(e) => {
      await this.setState({ 
        numerical_input: parseInt(this.state.numerical_input) + 1 
      });
      let numericalAnswer = this.state.questionList.map(question => 
        ({
          question_id : question.id,
          numerical_answer : this.state.numerical_input
        }))
      this.props.handler(numericalAnswer[0])
    }
  
    // DECREMENT FUNCTION FOR NUMERICAL QUESTION
    decrementItem = async(e) => {
      await this.setState({ 
        numerical_input: parseInt(this.state.numerical_input) - 1 
      });
      let numericalAnswer = this.state.questionList.map(question => 
        ({
          question_id : question.id,
          numerical_answer : this.state.numerical_input
        
        }))
      this.props.handler(numericalAnswer[0])
    }
  
    // FUNCTION TO RECORD ALL DATA CHANGES IN NUMERICAL INPUT AND STORE IT TO THE STATE
    numericalInputValue = async(e) =>{
      await this.setState({numerical_input:e.target.value})
      let value = parseInt(this.state.numerical_input)
      this.props.handler(value)
      console.log('numeric', this.props.numerics)
    }

    fillNumerical = numeric_id =>{
      let value = 0
      this.props.numerics.map(numeric => {
        if(numeric.question_id === numeric_id){
          value = numeric.numerical_answer
          return value
        }
      })
      return value
    }
    
  render(){
    return(
      <div className="numeric-question">
        <p className="question-text">{this.props.questions.question}</p>
      <div className="form-group button-wrapper">
      <div className="in-decrement">
        <button className="btn-in-decrement" onClick={this.decrementItem} >-</button>
        <input 
          type="number" 
          name={this.props.questions.id} 
          value={this.fillNumerical(this.props.questions.id)} 
          onChange={this.numericalInputValue} />
        <button className="btn-in-decrement" onClick={this.incrementItem}>+</button> 
        </div>
      </div>
      </div>
    )
  }
}

export default NumericChoice  