import React,{Component} from 'react';
import axios from 'axios';  
import {Navbar, Dropdown} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { API_URL} from '../../BaseUrlApi/Url'
import swal from 'sweetalert';
import './NavbarTable.css'
  class NavbarTable extends Component{


    logOut = async() =>{
      const headers = {
        'Authorization' : `Bearer ${localStorage.getItem('access_token')}`,
        'Content-Type' : 'application/json'
      }

      await axios.post(`${API_URL}/auth/logout`, [], {headers})
      localStorage.clear()
      window.location.href = '/login'
    }

    logOutAlert(){
      console.log('clicks')
      // return(<SweetAlert title="Here's a message!" onConfirm={this.onConfirm} />)
      swal({
        title: "LOG OUT",
        text: "Apakah anda yakin ingin Log Out dari aplikasi ini ?",
        buttons: ['Tidak', 'Ya'],
        dangerMode: true,
      })
      .then((removeData) => {
        if (removeData) {
          const logOutResponse = async() =>{
          const headers = {
            'Authorization' : `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type' : 'application/json'
          }
    
          await axios.post(`${API_URL}/auth/logout`, [], {headers})
          localStorage.clear()
          window.location.href = '/login'
        }
        logOutResponse()
        } else {
          return 
        }
      }); 
    }

   
    render(){
  
      return(
        <div className="navbar-body">
        <Navbar className="table-navbar">
          <div className="table-wrapper">
            <div className="wrap1">
              <h5 className="table-brand" href="#home">Daftar Keluarga</h5>
            </div>
            <div className="wrap2">
              <FontAwesomeIcon icon={faSearch} className="search-icon"/>
            </div>
            <div className="wrap3">
              <Dropdown>
                <Dropdown.Toggle>
                  <FontAwesomeIcon icon={faEllipsisV} className="menu-icon" />
                </Dropdown.Toggle>
                <Dropdown.Menu style={{
                    top:'0rem !important',
                    left : '2rem !important'
                  }}>
                  <Dropdown.Item href="#" className="item-list">User</Dropdown.Item>
                  <Dropdown.Item 
                    href="#" 
                    className="item-list" 
                    onClick={this.logOutAlert} >Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
              <div className="wrap4">
                  <p>No. KK</p>
              </div>
              <div className="wrap5">
                  <p>Kepala Keluarga</p>
              </div>
              <div className="wrap6">
                  <p>Aksi</p>
              </div>
            </div>
        </Navbar>
        
    
      </div>
    )
  }
}

export default NavbarTable