import React,{Component} from 'react'
import axios from 'axios'
import swal from 'sweetalert';
import Navbar from '../../Navbar/Navbar'
import ResidentPage from "./ResidentPage"
import Loader from '../../LoadingComponent/Loading'
import bgImage from '../../../asset/background.png'
import path from '../../../asset/Path 782.svg'
import './ResidentPages.css'
import {HEADERS, API_URL} from '../../BaseUrlApi/Url'

const headers = {
  'Authorization' : `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type' : 'application/json'
}
class ResidentPages extends Component{
  constructor(props){
    super(props)
    this.state ={
      resident_id:null,
      questions:[],
      question: null,
      nav_id:0,
      nav_title:'',
      selectedIndex:1,
      prevIndex:null,
      total_page:null,
      choices:[],
      numeric:[],
      multi:[],
      string:[],
      date:[],
      numeric_filter:[],
      string_filter:[],
      date_filter:[]
    }
    this.nextPage = this.nextPage.bind(this)
  }

  componentDidMount(){
    this.getSurveyQuestions()
  }
 
  getSurveyQuestions = () =>{
    axios.get(`${API_URL}/questions?question_group_id=2`, {headers})
    .then(response => {
      const questions = response.data.questions.map(question => (
        {
          id                : question.id,
          question          : question.question,
          question_type_id  : question.question_type_id,
          choices           : question.choices,
          children          : question.children
        }
      ))
      this.setState({
        questions: questions
      })
      console.log(this.state.questions)
      let getResidentQuestions = this.state.questions.map(res=>res)
      let navbarTitle = getResidentQuestions.map(res=>res.question)
      this.setState({
        question:getResidentQuestions[0],
        nav_title:navbarTitle[this.state.nav_id],
        total_page: getResidentQuestions.length,
        resident_id:this.props.match.params.value
      })
      console.log(this.state.resident_id)
    })
  }

   navigate = (id)=> {
    const question = this.state.questions.find(question => question.id === id)
    console.log(question)
    this.setState({question: question})
  }

  
  nextPage = () =>{
    let getQuestionId = this.state.questions.map(id => id.id)
    if(this.state.selectedIndex < getQuestionId.length){
      let getQuestions = this.state.questions.map(res=>res)
      let getNavTitle = getQuestions.map(res=>res.question)
      this.setState({
        selectedIndex: this.state.selectedIndex + 1,
        prevIndex: this.state.selectedIndex - 1,
      })
      let question = this.state.questions.find(question => question.id === getQuestionId[this.state.selectedIndex])
      this.setState({
        question:question,
        nav_title: getNavTitle[this.state.selectedIndex]
      })
    }
  }

  prevPage = () =>{
    let getQuestionId = this.state.questions.map(id => id.id)
    if(this.state.prevIndex < getQuestionId.length && this.state.prevIndex >= 0){
      let getQuestions = this.state.questions.map(res=>res)
      let getNavTitle = getQuestions.map(res=>res.question)
      this.setState({
        prevIndex : this.state.prevIndex - 1,
        selectedIndex : this.state.prevIndex + 1
      })
      let question = this.state.questions.find(question => question.id === getQuestionId[this.state.prevIndex])
      this.setState({
        question:question,
        nav_title: getNavTitle[this.state.prevIndex]
      })
    }
  }

  singleHandler = async(value) =>{
    this.setState({
      choices:value
    })
    console.log(this.state.choices)
  }

  numericHandler = async(value) => {
    this.setState({
      numeric:value
    })
    console.log(this.state.numeric)
  }

  multiHandler = async(value) =>{
    this.setState({
      multi:value
    })
  }
  
  stringHandler = async(value) => {
    this.setState({
      string:value
    })
  }

  dateHandler = async(value) => {
    this.setState({
      date:value
    })
  }

  savePage = () =>{
    const {choices, multi, numeric, string, date,resident_id} = this.state
 
    let multiResult = multi.map( result => (
      {
        question_id : result.question_id,
        choice_id : result.choice_id
      }
    ))

    const allChoices = [...multiResult, ...choices]
    const filterChoices = allChoices.filter(choice => {
      return Object.keys(choice).length !== 0
    })
    const allEssay = [...numeric, ...string, ...date]
    const filterEssay = allEssay.filter(essay => {
      return Object.keys(essay).length !== 0
    })

    if(filterChoices.length === 0 && filterEssay.length === 0){
      console.log('tidak bisa kirim data')
      swal({
        title: "Data Harus Diisi",
        icon: "warning",
        buttons: "OK",
        dangerMode: true,
      }) 
      return
    }

    let result = {
      resident_id : parseInt(resident_id),
      choices: filterChoices,
      essays: filterEssay
    }

    for (const key in result) {
      if(Array.isArray(result[key]) && !result[key].length)
        delete result[key]
    }
    swal({
      title: "Data Berhasil Disimpan!",
      icon: "success",
      button: "OK",
    });
    
    console.log(result)
    axios.post(`${API_URL}/answers`, result, {headers})
    .then(res => console.log(res.data)).catch(err=>console.log(err));
    this.props.history.push(`/detaildata/${resident_id}`);
  }

  render(){
    const {questions, nav_title, selectedIndex, total_page} = this.state 
      
    const background = {
      width:"100%",
      height:"100vh",
      backgroundSize:"cover",
      backgroundImage:`url(${bgImage})`,
      margin:"auto",
      positon: "relative"
    }
    if(questions.length>0){
      return( 
      <div className="question-page" style={background}>
       <Navbar 
        navbartitle={nav_title} 
        sidebar_id='2'
        side_title='Survei Individu' />
        <div className="question-container">
          <div className="question-text"> 
           {this.state.question ? 
            (<ResidentPage 
              questions={this.state.question.children} 
              singleHandler={this.singleHandler} 
              numericHandler={this.numericHandler}
              multiHandler={this.multiHandler}
              stringHandler={this.stringHandler}
              dateHandler={this.dateHandler}
              parent={this.state.question}/>) : ''}
          </div>
          <br/><br/>
          {selectedIndex < total_page ? 
            <div className="question-button">
              <button onClick={()=>this.prevPage()} className="prev-button">PREV</button>
              <button onClick={()=>this.nextPage()} className="next-button">NEXT</button>
            </div> 
              : 
            <div className="question-button">
              <button onClick={()=>this.prevPage()} className="prev-button">PREV</button>
              <button onClick={()=>this.savePage()} className="next-button">SAVE</button>
            </div>
          }
        </div>
        <img src={path} alt="" className="icon-quote"/>
      </div>
      )
    }else{
      return(
        <Loader/>
      )
    }
  }
}

export default ResidentPages