import React, {Component} from 'react'
import axios from 'axios'
import swal from 'sweetalert';
import {Link} from 'react-router-dom';
import {Navbar, ProgressBar, Table} from 'react-bootstrap'
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faChevronLeft,  faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons'
import './DetailData.css'
import {HEADERS, API_URL} from '../BaseUrlApi/Url'

const headers = {
  'Authorization' : `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type' : 'application/json'
}
class DetailDataIndividu extends Component{
  constructor(props){
  super(props)
  this.state={
      isLoaded: false,
      household_id:'',
      id:'',
      progress:40,
      nik:'',
      name:'',
      birthplace:'',
      birthdate:'',
      age:'',
      gender:'',
      religion_id:'',
      religion_name:'',
      marital_status_id:'',
      marital_status_name:'',
      education_status_id:'',
      education_status_name:'',
      job_status_id:'',
      job_status_name:'',
      alert:'',
      progress:null
  }
  // this.deleteData = this.deleteData.bind(this)
}
  componentDidMount(){
    this.getDetailData()
  }

  getDetailData = async(e) =>{
    try {
      this.setState({isLoaded: true})
      const page_value = this.props.match.params
      const getData = await axios.get(`${API_URL}/residents/${page_value.value}`, {headers})
      this.setState({
          household_id: getData.data.resident.household_id,
          id: getData.data.resident.id,
          nik: getData.data.resident.nik,
          name:getData.data.resident.name,
          birthplace:getData.data.resident.birthplace,
          birthdate:getData.data.resident.birthdate,
          age:getData.data.resident.age,
          gender:getData.data.resident.gender,
          religion_id:getData.data.resident.religion_id,
          marital_status_id:getData.data.resident.marital_status_id,
          education_status_id:getData.data.resident.highest_education_status_id,
          job_status_id:getData.data.resident.job_status_id,
          progress:getData.data.resident.progress
      })
      // console.log(getData)
      await this.getReligion()
      await this.getMaritalStatus()
      await this.getEducationStatus()
      await this.getJobStatus()
      this.setState({isLoaded: false})

    } catch(e) {
      console.log(e)
      this.setState({isLoaded: false})
    }
  }

  getReligion = async(e) =>{
    
      const religion_id = this.state.religion_id
      const getDataReligion = await axios.get(`https://vps.carakde.id/api_takalarsehat/api/v1/religions?id=${religion_id}`, {headers})
      this.setState({
          religion_name:getDataReligion.data.religions[0].name
      })
  }

  getMaritalStatus = async(e) =>{
    
      const marital_status_id = this.state.marital_status_id
      const getDataMaritalStatus = await axios.get(`https://vps.carakde.id/api_takalarsehat/api/v1/marital_statuses?id=${marital_status_id}`, {headers})
      this.setState({
          marital_status_name:getDataMaritalStatus.data.maritalStatuses[0].name
      })

  }

  getEducationStatus = async(e) =>{
    
      const education_status_id = this.state.education_status_id
      const getDataEducationStatus = await axios.get(`https://vps.carakde.id/api_takalarsehat/api/v1/education_statuses?id=${education_status_id}`, {headers})
      this.setState({
          education_status_name:getDataEducationStatus.data.educationStatuses[0].name
      })
  }

  getJobStatus = async(e) =>{
    
      const job_status_id = this.state.job_status_id
      const getDataJobStatus = await axios.get(`https://vps.carakde.id/api_takalarsehat/api/v1/job_statuses?id=${job_status_id}`, {headers})
      this.setState({
          job_status_name:getDataJobStatus.data.jobStatuses[0].name
      })
  }

  deleteData = async(e) =>{
    try {
      let delete_value = this.props.match.params.value
      await axios.delete(`${API_URL}/residents/${delete_value}`, {headers})
      this.props.history.push(`/biodatakeluarga/${this.state.household_id}`)
    } catch(e) {
      console.log(e)
    }
    
  }
    // DELETE HANDLER
    sweetalertfunction = async() => {
    console.log('clicks')
    // return(<SweetAlert title="Here's a message!" onConfirm={this.onConfirm} />)
    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons:["Nanti", "Hapus"],
      dangerMode: true,
    })
    .then((move) => {
      if (move) {
        this.deleteData()        
      } else {
       return
      }
    }); 
  }

  

  render(){
    const {household_id, id} = this.state
      return(
          <div className="detai-data-page">
            <div className={this.state.isLoaded === true ? "loading" : "d-none"}>
              <div className="loaders"></div>
                <h2 className="loading-soal text-center">Sedang memuat...</h2>
            </div>
          <Navbar className="navbar-kk">
            <Link to={`/biodatakeluarga/${household_id}`}>
              <FontAwesomeIcon icon={faChevronLeft} className="left-icon" />
            </Link>
            <h5 className="brand-kk" href="#home">Detail Data Individu</h5>
            <Link to={`/editdata/${id}`}>
              <FontAwesomeIcon icon={faEdit} className="data-edit-icon" />
            </Link>
            <FontAwesomeIcon onClick={this.sweetalertfunction} icon={faTrashAlt} className="data-trash-icon" />
          </Navbar>
          <div className="custom-table">
          <Table striped bordered hover className="data-table">
              <tbody>
                <tr>
                  <td>NIK</td>
                  <td>{this.state.nik}</td>
                </tr>
                <tr>
                  <td>Nama</td>
                  <td>{this.state.name}</td>
                </tr>
                <tr>
                  <td>Tempat Lahir</td>
                  <td>{this.state.birthplace}</td>
                </tr>
                <tr>
                  <td>Tanggal Lahir</td>
                  <td>{this.state.birthdate}</td>
                </tr>
                <tr>
                  <td>Umur</td>
                  <td>{this.state.age}</td>
                </tr>
                <tr>
                  <td>Jenis Kelamin</td>
                  <td>{this.state.gender}</td>
                </tr>
                <tr>
                  <td>Agama</td>
                  <td>{this.state.religion_name}</td>
                </tr>
                <tr>
                  <td>Status Nikah</td>
                  <td>{this.state.marital_status_name}</td>
                </tr>
                <tr>
                  <td>Pendidikan Terakhir</td>
                  <td>{this.state.education_status_name}</td>
                </tr>
                <tr>
                  <td>Status Pekerjaan</td>
                  <td>{this.state.job_status_name}</td>
                </tr>
              </tbody>
          </Table>
          </div>
      <br/><br/><br/>
      <hr className="devided-line"/>
      <br/><br/>
      <h1 className="survey-result">Hasil Survei</h1> <br/>
      <div className="data-wrapper">
        <div className="detail-data">
          <ProgressBar now={this.state.progress} label={100} srOnly className="progress"/>
        </div>
        <div>
          <p className="percentage">{this.state.progress}%</p>
        </div>
      </div>
      <br/><br/>
      <Link to={`/resident-survey/${id}`}>
        <button className="survey-button">Survei</button>
      </Link>
    </div>
    )
  }
}

export default DetailDataIndividu