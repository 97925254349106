import React, {Component} from 'react'
import {Navbar} from 'react-bootstrap';
import { ValidationForm, TextInput} from "react-bootstrap4-form-validation";
import { faChevronLeft,  faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import {Dropdown} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Link} from 'react-router-dom';
import axios from 'axios'
import swal from 'sweetalert';
import './FormDataIndividu.css'
import {API_URL} from '../BaseUrlApi/Url'

const headers = {
  'Authorization' : `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type' : 'application/json'
}
class DataDied extends Component{
    constructor(props){
        super(props);
        this.formRef = React.createRef();
        this.state = {
            isLoading: false,
            immediate:true,
            setFocusOnError:true,
            clearInputOnReset:false,
            household_id:null,
            name: '',
            age:'',
        }
      }

      componentDidMount(){
        this.setState({
          household_id:this.props.match.params.addvalue
        })
        // console.log(this.props.match.params.addvalue)
      }

      // SUBMIT CONTROLLER
      getData3 = async(e) =>{
        try {
            this.setState({isLoading: true})
            e.preventDefault();
            const serveport = {
                name : e.target.elements.name.value,
                age : e.target.elements.age.value,
                household_id: this.state.household_id
            }
            // console.log('isi form individu', serveport)  
            await axios.post(`${API_URL}/diedMembers`, serveport, {headers})
            this.setState({isLoading: false})
            this.props.history.push(`/biodatakeluarga/${this.state.household_id}`);
        } catch(e) {
            console.log(e)
            this.setState({isLoading: false})
        }
        
        
      }

      logOut = async() =>{
        const headers = {
          'Authorization' : `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type' : 'application/json'
        }
    
        await axios.post(`${API_URL}/auth/logout`, [], {headers})
        localStorage.clear()
        window.location.href = '/login'
      }
    
      logOutAlert(){
        console.log('clicks')
        // return(<SweetAlert title="Here's a message!" onConfirm={this.onConfirm} />)
        swal({
          title: "LOG OUT",
          text: "Apakah anda yakin ingin Log Out dari aplikasi ini ?",
          buttons: ['Tidak', 'Ya'],
          dangerMode: true,
        })
        .then((removeData) => {
          if (removeData) {
            const logOutResponse = async() =>{
            const headers = {
              'Authorization' : `Bearer ${localStorage.getItem('access_token')}`,
              'Content-Type' : 'application/json'
            }
      
            await axios.post(`${API_URL}/auth/logout`, [], {headers})
            localStorage.clear()
            window.location.href = '/login'
          }
          logOutResponse()
          } else {
            return 
          }
        }); 
      }

      
    render(){
      const {household_id} = this.state
        return(
        <div className="data-individu">
            <Navbar className="navbar-kk">
              <Link to={`/biodatakeluarga/${household_id}`}>
                <FontAwesomeIcon icon={faChevronLeft} className="left-icon" />
              </Link>
              <h5 className="brand-kk" href="#home">Data Individu yang meninggal</h5>
              <div className="data-individu-dropdown">
              <Dropdown>
                <Dropdown.Toggle>
                  <FontAwesomeIcon icon={faEllipsisV} className="menu-icon" />
                </Dropdown.Toggle>
                <Dropdown.Menu style={{
                    top:'0rem !important',
                    left : '2rem !important'
                  }}>
                  <Dropdown.Item href="/" className="item-list">User</Dropdown.Item>
                  <Dropdown.Item 
                    href="#" 
                    className="item-list" 
                    onClick={this.logOutAlert} >Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            </Navbar>
            <ValidationForm onSubmit={this.getData3} 
                        onErrorSubmit={this.handleErrorSubmit}
                        ref={this.formRef}
                        immediate={this.state.immediate}
                        setFocusOnError={this.state.setFocusOnError}>
                <div className="form-kk">
                    <div className="form-group-kk">
                        <label htmlFor="">Nama Anggota Keluarga</label>
                        <TextInput
                            type="text"
                            name="name"
                            // onChange={this.handleChange}
                            className="form-control col-sm-11 input-kk"
                            placeholder="Nama Responden"
                            successMessage="Looks good!"
                            errorMessage="Please enter something"
                            required
                        />
                    </div>
                    
                    <div className="form-group-kk">
                        <label htmlFor="">Umur</label>
                        <TextInput
                            type="number"
                            name="age"
                            // onChange={this.handleChange}
                            className="form-control col-sm-11 input-kk"
                            placeholder="Umur"
                            successMessage="Looks good!"
                            errorMessage="Please enter something"
                            required
                        />
                    </div>
                    <div className="form-group-kk">
                        <button type="submit" className={this.state.isLoading === false ? "btn-primary form-button" : "d-none"}>SIMPAN</button>
                        <button type="button" disabled className={this.state.isLoading === false ? "d-none" : "btn primary form-button"}>Loading...</button>
                    </div>

                </div>          
            </ValidationForm>
        </div>
        );
    }
}

export default DataDied;