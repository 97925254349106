import React,{Component} from 'react'
import './HouseholdQuestions.css'
class HouseholdQuestions extends Component{
  render(){
    // console.log(this.props.question)
    return(
      <div>
        <h1 className="question-title">{this.props.question.question}</h1>
        <br/>
        {this.props.children}
      </div>
    )
  }
}

export default HouseholdQuestions