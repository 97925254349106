import React from "react";
import DatePicker from "react-datepicker";
import './DateChoice.css'

import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

class DateChoice extends React.Component {
  state = {
    startDate: new Date(),
    id: null,
    date:'',
    selected_date:'',
    test:[]
  };

  componentDidMount(){
  }

  handleChange = async(date) => {
    await this.setState({
      startDate: date,
    });
    let value = this.state.startDate,
    mnth = ("0" + (value.getMonth()+1)).slice(-2),
    day  = ("0" + value.getDate()).slice(-2);
    let convertDate = [ value.getFullYear(), mnth, day ].join("-");

    let dateAnswer = {
      question_id : parseInt(this.state.id),
      date_answer : convertDate
    }
    this.props.handler(dateAnswer)
  };

  setQuestionId = async(e) =>{
    await this.setState({
      id : e.target.name
    })
  }

  selectedDate = date_id =>{
    let value = this.state.startDate
    this.props.dates.map(date => {
      if(date.question_id === date_id){
        value = new Date(date.date_answer)
        return value
      }
    })
    return value
  }



  render() {
    return (
    <div className="date-question">
    <p className="question-text"> {this.props.questions.question} </p>
      <div className="date-wrapper">
        <DatePicker
            dateFormat="yyyy-MM-dd"
            name={this.props.questions.id}
            selected={this.selectedDate(this.props.questions.id)}
            onChange={this.handleChange}
            onFocus={this.setQuestionId}
            fixedHeight
        />
      </div>
    </div>
    );
  }
}
export default DateChoice