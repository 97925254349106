export const LOGIN_URL = 'https://vps.carakde.id/api_takalarsehat/oauth/token'

export const API_URL = 'https://vps.carakde.id/api_takalarsehat/api/v1'

export const CLIENT_SECRET = 'lZRoJ1O9QpXBzKbZb0VzHT8cwMFDyUnZqCOSPyNx'

export const CLIENT_ID = 2

export const HEADERS = {
  'Authorization' : `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type' : 'application/json'
}