import React, {Component} from 'react';
import axios from 'axios'
import { Link, withRouter } from 'react-router-dom'
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { ValidationForm, TextInput} from "react-bootstrap4-form-validation"
import { faChevronLeft,  faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import {Navbar} from 'react-bootstrap';
import './FormHousehold2.css'
import {HEADERS, API_URL} from '../BaseUrlApi/Url'

const headers = {
  'Authorization' : `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type' : 'application/json'
}
class FormHousehold2 extends Component{

  constructor(props){
    super(props);
    // If you want to use the reset state function, you need to have a reference to the ValidationForm component
    //If your React < 16.3, check https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
    this.formRef = React.createRef();
    this.state = {
      isLoading: false,
      immediate:true,
      setFocusOnError:true,
      clearInputOnReset:false,
      family_card_number:'',
      number_of_family_members:'',
      number_of_toddlers:''
    }
  } 
  handleErrorSubmit2 = (e,formData, errorInputs) => {
      console.log(e,formData, errorInputs)
  }
  getData2 = async(e) =>{
    try {
      this.setState({isLoading: true})
      e.preventDefault();
      const setDataHousehold = {
        province_id : localStorage.getItem('province_id'),
        city_id :localStorage.getItem('city_id'),
        district_id :localStorage.getItem('district_id'),
        village_id :localStorage.getItem('village_id'),
        hamlet_id :localStorage.getItem('hamlet_id'),
        neighborhood_association :localStorage.getItem('neighborhood_association'),
        citizens_association :localStorage.getItem('citizens_association'),
        latitude :localStorage.getItem('latitude'),
        longitude :localStorage.getItem('longitude'),
        family_card_number : e.target.elements.family_card_number.value,
        number_of_family_members : e.target.elements.number_of_family_members.value,
        number_of_toddlers : e.target.elements.number_of_toddlers.value,
      }
      let postData = await axios.post(`${API_URL}/households`, setDataHousehold, {headers})
      // .then(res => ).catch(err=>console.log(err));
      this.props.history.push(`/biodatakeluarga/${postData.data.household.id}`)
    } catch(e) {
      console.log(e)
      this.setState({isLoading: false})
    }
    
  }
  render(){
    return(
      <div>
      <Navbar className="navbar-kk">
        <Link to="/household-form">
          <FontAwesomeIcon icon={faChevronLeft} className="left-icon" />
        </Link>
        <h5 className="brand-kk" href="#home">Keterangan Rumah Tangga</h5>
        <FontAwesomeIcon icon={faEllipsisV} className="form-menu-icon" />
      </Navbar>
      <ValidationForm 
        onSubmit={this.getData2} 
        onErrorSubmit={this.handleErrorSubmit2}
        ref={this.formRef}
        immediate={this.state.immediate}
        setFocusOnError={this.state.setFocusOnError}
        // defaultErrorMessage={{ required: "Please enter something."}} 
        >
        <div className="form-page-content2">
          <div className="form-page-group">
            <label htmlFor="">No. Kartu Keluarga</label>
            <TextInput 
              type="number" 
              name="family_card_number"
              className="form-control col-sm-11 input-text2"
              placeholder="00"
              successMessage="Looks good!"
              errorMessage="Please enter something"
              required
            />
          </div>
          <div className="form-page-group">
            <label htmlFor="">Jumlah Anggota Rumah Tangga</label>
            <TextInput 
              type="number" 
              name="number_of_family_members"
              className="form-control col-sm-11 input-text2"
              placeholder="00"
              successMessage="Looks good!"
              errorMessage="Please enter something"
              required
            />
          </div>
          <div className="form-page-group">
            <label htmlFor="">Banyaknya Balita(0-59)</label>
            <TextInput 
              type="number" 
              name="number_of_toddlers"
              className="form-control col-sm-11 input-text2"
              placeholder="00"
              successMessage="Looks good!"
              errorMessage="Please enter something"
              required
            />
          </div>
          <div className="form-group">
            <button type="submit" className="btn2 primary btn">SIMPAN</button>
          </div>
        </div>
        </ValidationForm>
      </div>
    );
  }
}

export default withRouter(FormHousehold2);

