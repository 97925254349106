import React, { Component } from "react";
import "./SingleChoice.css";


class SingleChoice extends Component {
  constructor(props){
    super(props)
    this.state = {
      choices:[],
      newChoices:null,
      choice_id:null,
      jump_ques:null
      
    }
  }
  componentDidMount() {
  }
  // FUNCTION TO SET ALL RADIO INPUT AND STORE IT TO STATE CHOICE
  changeHandler = async e => {
    let newChoice = {
      question_id: parseInt(e.target.name),
      choice_id: parseInt(e.target.value)
    };

    this.setState({
      choice_id:parseInt(e.target.value)
    })

    //skippingQuestions
    // let selectedChoice = this.props.questions.choices.find(choice => choice.id === parseInt(e.target.value))
    // console.log('selected', selectedChoice)
    // this.props.questions.choices.map(async(choice) => {
    //   if(choice.id === this.state.choice_id){
    //     if(choice.skipped_questions.length > 0){
    //       let skipped_questions = choice.skipped_questions.map((skipped_question) => {
    //         return skipped_question.id
    //       })
    //       this.props.addSkippedQuestionsHandler(skipped_questions)
    //     }
        
    //   } 
    //   //else {
    //   //    choice.skipped_questions.map((skipped_question) => {
    //   //      this.props.removeSkippedQuestionHandler(skipped_question.id)
    //   //   })
    //   // }
    // })
    
    this.props.handler(newChoice);
  };

  

  fillRadioButton = choice_id => {
    let checked = false;
    this.props.choices.map(choice => {
      if (choice.choice_id === choice_id) {
        checked = true;
      }
    });
    return checked;
  };

  

  render() {
    return (
      <div>
      <div className="single-question">
        <p className="question-text">{this.props.questions.question}</p>
        <div className="single-button-wrapper">
          {this.props.questions.choices.map((choice, index) => (
            <div key={choice.id} className="single-options">
              <input
                checked={!!this.fillRadioButton(choice.id)}
                type="radio"
                value={choice.id}
                id={choice.id}
                name={this.props.questions.id}
                onChange={this.changeHandler}
              />
              <label htmlFor={choice.id} className="choice-answer">
                {choice.answer}
              </label>
              <span className="checkmark"></span>
            </div>
          ))}
        </div>
      </div>
      {/* {this.state.jump_ques !== null? 
          <div className="single-question">
            <div className="single-options">
              <p className="question-text">{this.state.jump_ques.question}</p>
              <div className="single-button-wrapper">
                {this.state.jump_ques.choices.map(choice => 
                  (
                  <div key={choice.id} className="single-options">
                    <input 
                      checked={!!this.fillJumpQuestion(choice.id)}
                      type="radio" 
                      value={choice.id}
                      id={choice.id}
                      name={this.state.jump_ques.id} 
                      onChange={this.jumpChangeHandler}
                    />
                    <label htmlFor={choice.id} className="choice-answer">{choice.answer}</label>
                    <span className="checkmark"></span>
                  </div>
                )                               
             
                )}
              </div>
            </div>
          </div>
          :
          ''
          } */}
      </div>
    );
  }
}

export default SingleChoice;
