import React from 'react'
import { slide as Menu } from 'react-burger-menu'
import close from '../../asset/left-arrow.svg'
import {Link} from 'react-router-dom'
import './Sidebar.css'
import axios from 'axios'
import {HEADERS, API_URL} from '../BaseUrlApi/Url'
import swal from 'sweetalert';

const headers = {
  'Authorization' : `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type' : 'application/json'
}
class Sidebar extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      isOpen: false,
      sidebar:[]
    }
  }
  componentDidMount(){
    this.getSidebar()
    console.log(this.props.sidebar_id)
  }

  getSidebar = async(e) => {
    let getSidebarDatas = await axios.get(`${API_URL}/question_categories?question_group_id=${this.props.sidebar_id}`, {headers})
    let sidebarData = getSidebarDatas.data.questionCategories.map(data => (
      {
        sidebar_id        : data.id,
        sidebar_title     : data.question,
        sidebar_type      : data.question_type_id,
        sidebar_children  : data.children
      }
    ))
    this.setState({
      sidebar:sidebarData
    })
    // console.log(this.state.sidebar)
    // console.log(getSidebarDatas.data.questionCategories)
  }

  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen})  
  }
  closeMenu () {
    this.setState({menuOpen: false})
  }
  toggleMenu () {
    this.setState(state => ({menuOpen: !state.menuOpen}))
  }
  logOut = async() =>{
    const headers = {
      'Authorization' : `Bearer ${localStorage.getItem('access_token')}`,
      'Content-Type' : 'application/json'
    }

    await axios.post(`${API_URL}/auth/logout`, [], {headers})
    localStorage.clear()
    window.location.href = '/login'
  }

  logOutAlert(){
    swal({
      title: "LOG OUT",
      text: "Apakah anda yakin ingin Log Out dari aplikasi ini ?",
      buttons: ['Tidak', 'Ya'],
      dangerMode: true,
    })
    .then((removeData) => {
      if (removeData) {
        const logOutResponse = async() =>{
        const headers = {
          'Authorization' : `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type' : 'application/json'
        }
  
        await axios.post(`${API_URL}/auth/logout`, [], {headers})
        localStorage.clear()
        window.location.href = '/login'
      }
      logOutResponse()
      } else {
        return 
      }
    }); 
  }
  render () {
    return (
      <div className="sidebar-menu">
      <Menu 
        width='85%'
        isOpen={this.state.menuOpen}
        onStateChange={(state) => this.handleStateChange(state)}
        customCrossIcon= {false}
      >
        <h2 className="sidebar-title">{this.props.sidtitle}</h2>
        <img src={close} alt="" className="close-button" onClick={() => this.toggleMenu()}/>
        <span className="title-underline"></span>
        {this.state.sidebar.map(sidebar => (
          <div>
          <Link to="/form2" onClick={() => this.closeMenu()} className="list-options">{sidebar.sidebar_title}</Link>
            {sidebar.sidebar_children.length > 0 ? (
              <div>
                {sidebar.sidebar_children.map(child => (
                  <Link to="/soal" className="child-options">{child.question}</Link>
                ))}
              </div>
            ) : ''}
          </div>
        ))}
        <Link to="/form1" className="logout" onClick={this.logOutAlert}>Log Out</Link>
      </Menu>
    </div>
    );
  }
}

export default Sidebar;