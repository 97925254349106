import React, {Component} from 'react'
import ResidentQuestions from './ResidentQuestions'
import SingleChoice from '../HouseholdChoice/SingleChoice/SingleChoice'
import MultiChoice from '../HouseholdChoice/MultiChoice/MultiChoice'
import NumericChoice from '../HouseholdChoice/NumericChoice/NumericChoice'
import StringChoice from '../HouseholdChoice/StringChoice/StringChoice'
import DateChoice from '../HouseholdChoice/DateChoice/DateChoice'

class ResidentPage extends Component{
  constructor(props){
    super(props)
    this.state = {
      choices:[],
      numerics:[],
      multi:[],
      strings:[],
      dates:[]
    }
  }

  componentDidMount(){
  }

  singleChoiceHandler = async(newChoice) =>{
    let choices = this.state.choices.filter(choice => {
      return choice.question_id !== newChoice.question_id
    })
    await this.setState({
      choices: [...choices, newChoice]
    })
    this.props.singleHandler(this.state.choices)
  }

  numericChoiceHandler = async(value) =>{
    let numeric = this.state.numerics.filter(numeric => {
      return numeric.question_id !== value.question_id
    })
    await this.setState({
      numerics:[...numeric, value]
    })
    this.props.numericHandler(this.state.numerics)
  }

  multiChoiceHandler = async(multiValue) =>{
    let multi = this.state.multi.filter(multi => {
      return multi.choice_id !== multiValue.choice_id 
    })
    const mergeMulti = [...multi, multiValue]
    let filterMulti = mergeMulti.filter(multi => {
      return multi.checked === true
    })
    await this.setState({
      multi: filterMulti
    })
    console.log(this.state.multi)
    this.props.multiHandler(this.state.multi)
  }

  stringChoiceHandler = async(value) =>{
    let string = this.state.strings.filter(string => {
      return string.question_id !== value.question_id
    })
    await this.setState({
      strings:[...string, value]
    })
    this.props.stringHandler(this.state.strings)
  }

  dateChoiceHandler = async(value) =>{
    let date = this.state.dates.filter(date => {
      return date.question_id !== value.question_id
    })
    await this.setState({
      dates:[...date, value]
    })
    this.props.dateHandler(this.state.dates)
  }


  getQuestions = (question) =>{
    if(question.children.length > 0){
      return(
        <ResidentQuestions key = {question.id} question={question}>
          {question.children.map(this.getQuestions)}
        </ResidentQuestions>
      )
    }else{

      switch (question.question_type_id) {
        case 1:
          return <SingleChoice 
                    handler = {this.singleChoiceHandler} 
                    choices={this.state.choices} 
                    questions={question}/>
          break;
      
        case 2 :
          return <MultiChoice 
                    multi={this.state.multi}
                    handler={this.multiChoiceHandler}
                    questions={question} />
          break;
        
        case 3 :
          return <StringChoice 
                    strings={this.state.strings}
                    handler={this.stringChoiceHandler}
                    questions={question}/>
          break;

        case 4:
          return <NumericChoice 
                    numerics={this.state.numerics}
                    handler={this.numericChoiceHandler}
                    questions={question}/>
          break;
        
        case 5:
          return <DateChoice
                    dates={this.state.dates}  
                    handler={this.dateChoiceHandler}
                    questions={question} />
          break;
          default:
          break;
      }
    }
  }

  render(){
    return(
      <ResidentQuestions question={this.props.parent}>
        {this.props.questions.map(this.getQuestions)}
      </ResidentQuestions>
    )
  }
}

export default ResidentPage