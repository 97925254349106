import React, {Component} from 'react'
import {Navbar} from 'react-bootstrap';
import { ValidationForm, TextInput} from "react-bootstrap4-form-validation";
import { faChevronLeft,  faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import {Dropdown} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Link} from 'react-router-dom';
import axios from 'axios'
import swal from 'sweetalert';
import './FormDataIndividu.css'
import {API_URL} from '../BaseUrlApi/Url'

const headers = {
  'Authorization' : `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type' : 'application/json'
}
class DataIndividu extends Component{
    constructor(props){
        super(props);
        this.formRef = React.createRef();
        this.state = {
            isLoading: false,
            immediate:true,
            setFocusOnError:true,
            clearInputOnReset:false,
            household_id:null,
            head_name:'',
            nik:'',
            birthplace:'',
            birthdate:'',
            age:'',
            gender:'',
            religion:'',
            religions:[],
            relation:'',
            relations:[],
            marital_status:'',
            marital_statuses:[],
            education_status:'',
            education_statuses:[],
            job_status:'',
            job_statuses:[],
            checked:false,
            checked2:false,
            startDate: new Date(),
            date:'',
            date_id:null
        }
      }

      componentDidMount(){
        this.getDataReligions()
        this.getDataRelations()
        this.getDataMaritalStatuses()
        this.getDataEducationStatuses()
        this.getDataJobStatuses()
        this.setState({
          household_id:this.props.match.params.addvalue
        })
        // console.log(this.props.match.params.addvalue)
      }

      // THIS FUNCTION IS TO GET THE DATA OF BIRTHDATE
      birthdateHandler =  async(e) =>{
        await this.setState({
          birthdate:e.target.value
        })
      }
      
      // THIS FUNCTION IS TO GET THE DATA OF FEMALE RADIO BUTTON
      femaleHandler = async(e) =>{
        await this.setState({
          checked:true,
          checked2:false
        })
        if(this.state.checked){
          this.setState({
            gender:'female'
          })
        }
      }

      // THIS FUNCTION IS TO GET THE DATA OF MALE RADIO BUTTON
      maleHandler = async(e) =>{
        await this.setState({
          checked:false,
          checked2:true,
        })
        if(this.state.checked2){
          this.setState({
            gender:'male'
          })
        }      
      }

      // THIS FUNCTION IS TO GET THE DATA OF RELIGIONS
      getDataReligions = async(e) => {
        let religionsMap = await axios.get(`${API_URL}/religions`, {headers})
        let getReligion = religionsMap.data.religions.map(religion=>(
          {
            value:religion.id,
            label:religion.name
          }
        ))
        await this.setState({
          religions:getReligion
        })
      }

      // THIS FUNCTION IS TO CHANGE THE STATE OF RELIGION
      getReligion = async(e) =>{
        await this.setState({
          religion:e.target.value
        })
      }
      
      // THIS FUNCTION IS TO GET THE DATA OF RELATION_TO_HEAD_OF_HOUSEHOLDS
      getDataRelations = async(e) =>{
        let relationsMap = await axios.get(`${API_URL}/relation_to_head_of_households`, {headers})
        let getRelationStatuses = await relationsMap.data.relationToHeadOfHouseholds.map(relationStatus => (
          {
            value:relationStatus.id,
            label:relationStatus.name
          }
        ))
        await this.setState({
          relations:getRelationStatuses
        })
      }

      // THIS FUNCTION IS TO CHANGE THE STATE OF RELATION_TO_HEAD_OF_HOUSEHOLDS
      getRelation = async(e) =>{
        await this.setState({
          relation:e.target.value
        })
      }

      // THIS FUNCTION IS TO GET THE DATA OF MARTIAL STATUSES
      getDataMaritalStatuses = async(e) =>{
        let maritalStatusesMap = await axios.get(`${API_URL}/marital_statuses`, {headers})
        let getMaritalStatuses = await maritalStatusesMap.data.maritalStatuses.map(maritalStatus => (
          {
            value:maritalStatus.id,
            label:maritalStatus.name
          }
        ))
        await this.setState({
          marital_statuses:getMaritalStatuses
        })
      }

      // THIS FUNCTION IS TO CHANGE THE STATE OF MARITAL STATUS
      getMaritalStatus = async(e) => {
        await this.setState({
          marital_status:e.target.value
        })
      }

      // THIS FUNCTION IS TO GET THE DATA OF EDUCATION STATUSES
      getDataEducationStatuses = async(e) =>{
        let educationStatusesMap = await axios.get(`${API_URL}/education_statuses`, {headers})
        let getEducationStatuses = await educationStatusesMap.data.educationStatuses.map(educationStatus => (
          {
            value:educationStatus.id,
            label:educationStatus.name
          }
        ))
        await this.setState({
          education_statuses:getEducationStatuses
        })
      }

      // THIS FUNCTION IS TO CHANGE THE STATE OF EDUCATION STATUS
      getEducationStatus = async(e) => {
        await this.setState({
          education_status:e.target.value
        })
      }

      // THIS FUNCTION IS TO GET THE DATA OF JOB STATUSES
      getDataJobStatuses = async(e) =>{
        let jobStatusesMap = await axios.get(`${API_URL}/job_statuses`, {headers})
        let getJobStatuses = await jobStatusesMap.data.jobStatuses.map(jobStatus => (
          {
            value:jobStatus.id,
            label:jobStatus.name
          }
        ))
        await this.setState({
          job_statuses:getJobStatuses
        })
      }
      
      // THIS FUNCTION IS TO CHANGE THE STATE OF JOB STATUS
      getJobStatus = async(e) => {
        await this.setState({
          job_status:e.target.value
        })
      }

      // BIRTHDATE HANDLER

      handleChange = async(date) => {
        await this.setState({
          startDate: date,
        });

        if(this.state.startDate !== null){
          this.dateConverter(this.state.startDate)
        }
      };

      dateConverter = value => {
        let date = value,
        mnth = ("0" + (date.getMonth()+1)).slice(-2),
        day  = ("0" + date.getDate()).slice(-2);
        let convertDate = [ date.getFullYear(), mnth, day ].join("-");
        this.setState({
          selected_date:convertDate
        })
        console.log(this.state.selected_date)
    }


      // SUBMIT CONTROLLER
      getData3 = async(e) =>{
        this.setState({isLoading: true})
        e.preventDefault();
        const serveport = {
            name : e.target.elements.head_name.value,
            nik : e.target.elements.nik.value,
            birthplace : e.target.elements.birthplace.value,
            birthdate : this.state.selected_date,
            // age : e.target.elements.age.value,
            religion_id: this.state.religion,
            relation_to_head_of_household_id : this.state.relation,
            marital_status_id : this.state.marital_status,
            highest_education_status_id : this.state.education_status,
            job_status_id :this.state.job_status,
            gender : e.target.elements.gender.value,
            
        }
        console.log('isi form individu', serveport)

        let value= this.props.match.params.addvalue;     
        axios.post(`${API_URL}/residents?household_id=${value}`,serveport, {headers})
        .then(res => {
          if(res.data.resident.relation_to_head_of_household_id === "1"){
            const updateHead = {
              _method: 'PATCH',
              head_id: res.data.resident.id
            }
            console.log('utk update', updateHead)
            axios.post(`${API_URL}/households/${res.data.resident.household_id}`, updateHead, {headers})
            .then(res => {
              this.props.history.push(`/biodatakeluarga/${value}`);
            }).catch(err => {
              this.setState({isLoading: false})
              console.log(err)
            })
            // return
          }
          else {
            this.props.history.push(`/biodatakeluarga/${value}`);
          }
        })
        .catch(err=> {
          this.setState({isLoading: false})
          console.log(err)
        });
      }

      logOut = async() =>{
        const headers = {
          'Authorization' : `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type' : 'application/json'
        }
    
        await axios.post(`${API_URL}/auth/logout`, [], {headers})
        localStorage.clear()
        window.location.href = '/login'
      }
    
      logOutAlert(){
        console.log('clicks')
        // return(<SweetAlert title="Here's a message!" onConfirm={this.onConfirm} />)
        swal({
          title: "LOG OUT",
          text: "Apakah anda yakin ingin Log Out dari aplikasi ini ?",
          buttons: ['Tidak', 'Ya'],
          dangerMode: true,
        })
        .then((removeData) => {
          if (removeData) {
            const logOutResponse = async() =>{
            const headers = {
              'Authorization' : `Bearer ${localStorage.getItem('access_token')}`,
              'Content-Type' : 'application/json'
            }
      
            await axios.post(`${API_URL}/auth/logout`, [], {headers})
            localStorage.clear()
            window.location.href = '/login'
          }
          logOutResponse()
          } else {
            return 
          }
        }); 
      }

      
    render(){
      const {household_id} = this.state
        return(
        <div className="data-individu">
            <Navbar className="navbar-kk">
              <Link to={`/biodatakeluarga/${household_id}`}>
                <FontAwesomeIcon icon={faChevronLeft} className="left-icon" />
              </Link>
              <h5 className="brand-kk" href="#home">Data Individu</h5>
              <div className="data-individu-dropdown">
              <Dropdown>
                <Dropdown.Toggle>
                  <FontAwesomeIcon icon={faEllipsisV} className="menu-icon" />
                </Dropdown.Toggle>
                <Dropdown.Menu style={{
                    top:'0rem !important',
                    left : '2rem !important'
                  }}>
                  <Dropdown.Item href="/" className="item-list">User</Dropdown.Item>
                  <Dropdown.Item 
                    href="#" 
                    className="item-list" 
                    onClick={this.logOutAlert} >Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            </Navbar>
            <ValidationForm onSubmit={this.getData3} 
                        onErrorSubmit={this.handleErrorSubmit}
                        ref={this.formRef}
                        immediate={this.state.immediate}
                        setFocusOnError={this.state.setFocusOnError}>
                <div className="form-kk">
                    <div className="form-group-kk">
                        <label htmlFor="">Nama Responden</label>
                        <TextInput
                            type="text"
                            name="head_name"
                            // onChange={this.handleChange}
                            className="form-control col-sm-11 input-kk"
                            placeholder="Nama Responden"
                            successMessage="Looks good!"
                            errorMessage="Please enter something"
                            required
                        />
                    </div>
                    <div className="form-group-kk">
                        <label htmlFor="">NIK</label>
                        <TextInput
                            type="number"
                            name="nik"
                            // onChange={this.handleChange}
                            className="form-control col-sm-11 input-kk"
                            placeholder="NIK"
                            successMessage="Looks good!"
                            errorMessage="Please enter something"
                            required
                        />
                    </div>
                    <div className="form-group-kk">
                        <label htmlFor="">Tempat Lahir</label>
                        <TextInput
                            type="text"
                            name="birthplace"
                            className="form-control col-sm-11 input-kk"
                            placeholder="Tempat Lahir"
                            successMessage="Looks good!"
                            errorMessage="Please enter something"
                            required
                        />
                    </div>
                    <label htmlFor="" className="birthdate-label">Tanggal Lahir</label>
                    <DatePicker
                            dateFormat="yyyy-MM-dd"
                            name="birthdate"
                            selected={this.state.startDate}
                            onChange={this.handleChange}
                            fixedHeight
                        />
                    {/* <div className="form-group-kk">
                        <label htmlFor="">Umur</label>
                        <TextInput
                            type="number"
                            name="age"
                            // onChange={this.handleChange}
                            className="form-control col-sm-11 input-kk"
                            placeholder="Umur"
                            successMessage="Looks good!"
                            errorMessage="Please enter something"
                            required
                        />
                    </div> */}
                    <div className="form-group-kk">
                        <label htmlFor="">Jenis Kelamin</label>
                        <div className="kk-button-wrapper">
                          <div 
                            className="male" 
                            onChange={this.maleHandler} 
                            style={{background: this.state.checked2? '#1BAEC9 ' : '#DCEEF5', 
                            color:this.state.checked2? 'white':'black'}} >
                            <label htmlFor="male" >Male</label>
                            <input 
                                type="radio" 
                                name="gender" 
                                id="male" 
                                value="Male" />
                          </div>
                          <div 
                            className="female"  
                            onChange={this.femaleHandler} 
                            style={{backgroundColor: this.state.checked? '#1BAEC9' : '#DCEEF5', 
                            color:this.state.checked? 'white':'black'}}>
                            <label htmlFor="female" >Female</label>
                            <input 
                                type="radio"
                                name="gender" 
                                id="female" 
                                value="Female" />
                          </div>
                        </div>
                    </div>
                    <div className="form-group-kk">
                        <label htmlFor="">Agama</label>
                        <select onChange={this.getReligion}>
                        <option disabled selected>--- Pilih Agama ---</option>
                          {this.state.religions.map(religion => 
                            <option key={religion.value} value={religion.value}>{religion.label}</option>
                          )}
                        </select>
                    </div>
                    <div className="form-group-kk">
                        <label htmlFor="">Hubungan dengan Kepala Rumah Tangga</label>
                        <select onChange={this.getRelation}>
                          <option disabled selected>--- Pilih Status Hubungan ---</option>
                          {this.state.relations.map(relation => 
                            <option key={relation.value} value={relation.value}>{relation.label}</option>
                          )}
                        </select>
                    </div>
                    <div className="form-group-kk">
                        <label htmlFor="">Status Perkawinan</label>
                        <select onChange={this.getMaritalStatus}>
                        <option disabled selected>--- Pilih Status Perkawinan ---</option>
                          {this.state.marital_statuses.map(maritalStatus => 
                            <option key={maritalStatus.value} value={maritalStatus.value}>{maritalStatus.label}</option>
                          )}
                        </select>
                    </div>
                    <div className="form-group-kk">
                        <label htmlFor="">Status Pendidikan Tertinggi yang Ditamatkan</label>
                        <select onChange={this.getEducationStatus}>
                          <option disabled selected>--- Pilih Status Pendidikan  ---</option>
                          {this.state.education_statuses.map(educationStatus => 
                            <option key={educationStatus.value} value={educationStatus.value}>{educationStatus.label}</option>
                          )}
                        </select>
                    </div>
                    <div className="form-group-kk">
                        <label htmlFor="">Status Pekerjaan</label>
                        <select onChange={this.getJobStatus}>
                        <option disabled selected>--- Pilih Status Pekerjaan ---</option>
                        {this.state.job_statuses.map(jobStatus => 
                          <option key={jobStatus.value} value={jobStatus.value}>{jobStatus.label}</option>
                        )}
                        </select>
                    </div>
                    <div className="form-group-kk">
                        <button type="submit" className={this.state.isLoading === false ? "btn-primary form-button" : "d-none"}>SIMPAN</button>
                        <button type="button" disabled className={this.state.isLoading === false ? "d-none" : "btn primary form-button"}>Loading...</button>
                    </div>

                </div>          
            </ValidationForm>
        </div>
        );
    }
}

export default DataIndividu;