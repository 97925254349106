import React, {Component} from 'react'
import HouseholdQuestions from './HouseholdQuestions'
import SingleChoice from './HouseholdChoice/SingleChoice/SingleChoice'
import MultiChoice from './HouseholdChoice/MultiChoice/MultiChoice'
import NumericChoice from './HouseholdChoice/NumericChoice/NumericChoice'
import StringChoice from './HouseholdChoice/StringChoice/StringChoice'
import DateChoice from './HouseholdChoice/DateChoice/DateChoice'
import axios from 'axios'
import {API_URL} from '../BaseUrlApi/Url'
import { async } from 'q'

const headers = {
  'Authorization' : `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type' : 'application/json'
}
class HouseholdPage extends Component{
  constructor(props){
    super(props)
    this.state ={
      choices:[],
      numerics:[],
      multi:[],
      strings:[],
      dates:[],
      questions: [],
    }
  }

  componentDidMount(){
    
  }


  singleChoiceHandler = async(newChoice) =>{
    let choices = this.state.choices.filter(choice => {
      return choice.question_id !== newChoice.question_id
    })
    await this.setState({
      choices: [...choices, newChoice]
    })
    this.props.singleHandler(this.state.choices)
  }
  

  numericChoiceHandler = async(value) =>{
    let numeric = this.state.numerics.filter(numeric => {
      return numeric.question_id !== value.question_id
    })
    await this.setState({
      numerics:[...numeric, value]
    })
    this.props.numericHandler(this.state.numerics)
  }

  multiChoiceHandler = async(multiValue) =>{
    let multi = this.state.multi.filter(multi => {
      return multi.choice_id !== multiValue.choice_id 
    })
    const mergeMulti = [...multi, multiValue]

    let filterMulti = mergeMulti.filter(multi => {
      return multi.checked === true
    })
    await this.setState({
      multi: filterMulti
    })
    this.props.multiHandler(this.state.multi)
  }

  stringChoiceHandler = async(value) =>{
    let string = this.state.strings.filter(string => {
      return string.question_id !== value.question_id
    })
    await this.setState({
      strings:[...string, value]
    })
    this.props.stringHandler(this.state.strings)
  }

  dateChoiceHandler = async(value) =>{
    let date = this.state.dates.filter(date => {
      return date.question_id !== value.question_id
    })
    await this.setState({
      dates:[...date, value]
    })
    this.props.dateHandler(this.state.dates)
  }

  getQuestions = (question) =>{
    if(question.children.length > 0){
      return(
        <HouseholdQuestions key = {question.id} question={question}>
          {question.children.map(this.getQuestions)}
        </HouseholdQuestions>
      )
    }else{
      // if(!question.skipped ){
        switch (question.question_type_id) {
          case 1:
            return <SingleChoice 
                      
                      choices={this.state.choices} 
                      questions={question} 
                      handler = {this.singleChoiceHandler}
                      jumpQuestion = {this.jumpQuestion}
                      />
            break;
        
          case 2 :
            return <MultiChoice 
                      
                      multi={this.state.multi}
                      handler={this.multiChoiceHandler} 
                      questions={question} />
            break;
          
          case 3 :
            return <StringChoice 
                      
                      strings={this.state.strings}
                      handler={this.stringChoiceHandler} 
                      questions={question}/>
            break;
  
          case 4:
            return <NumericChoice 
                      
                      numerics={this.state.numerics}
                      handler={this.numericChoiceHandler} 
                      questions={question}/>
            break;
          
          case 5:
            return <DateChoice
                      
                      dates={this.state.dates} 
                      handler={this.dateChoiceHandler} 
                      questions={question} />
            break;
            default:
            break;
        }
      // }
    }
  }

    render(){
    return(
      <div>
      <HouseholdQuestions question={this.props.parent}>
        {this.props.questions.map(this.getQuestions)}
      </HouseholdQuestions>
    </div>
    )
  }
}

export default HouseholdPage