import React,{Component} from 'react'
import axios from 'axios'; 
import swal from 'sweetalert';
import {Navbar, Dropdown, ProgressBar} from 'react-bootstrap';
import {faEllipsisV, faEdit, faChevronLeft,  faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import image from "../../asset/add-friend.svg"
import BootstrapTable from 'react-bootstrap-table-next'
import {Link, Redirect} from 'react-router-dom';
import {HEADERS, API_URL} from '../BaseUrlApi/Url'
import "./BiodataKeluarga.css"

function bioFormatter(cell, row, rowIndex, formatExtraData) { 
  // console.log(row)
  return ( 
      <Link to={`/detaildata/${row.id}`}>
        <button onClick={()=>{console.log(row.id)}} className="survei-button">Detail</button> 
      </Link>
); } 

function diedFormatter(cell, row, rowIndex, formatExtraData) { 
  // console.log(row)
  return ( 
      <Link to={`/detaildata-died/${row.id}`}>
        <button onClick={()=>{console.log(row.id)}} className="survei-button">Detail</button> 
      </Link>
); } 

const headers = {
  'Authorization' : `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type' : 'application/json'
}

class BiodataKeluarga extends Component{
  constructor(props){
  super(props)
  this.state={
    isLoaded: false,
    progress:null,
    kk:'',
    household_id:'',
    head_name:'',
    head_id:'',
    hamlet_id:'',
    village_id:'',
    district_id:'',
    city_id:'',
    province_id:'',
    hamlet_name:'',
    village_name:'',
    district_name:'',
    city_name:'',
    province_name:'',
    bioproducts:[],
    died_members: [],
    biocolumns:[
      {
        dataField: 'nik',
        text: 'NIK'
      },
      {
        dataField: 'name',
        text: 'Nama',
        style:{textAlign:'left', paddingLeft:'4rem'}
      },
      {
        dataField: 'relation_to_head_of_household.name',
        text: 'Hubungan'
      },
      {
        dataField: 'Aksi',
        text: 'Aksi',
        formatter:bioFormatter
      }
    ],
    pathname:'',
  }
  this.editHeadHandler = this.editHeadHandler.bind(this)
  }
  

  async componentDidMount() {
    await this.getKepalaKeluarga()
    await this.getDataKeluarga()
    this.getDiedMembers()
  } 


  getKepalaKeluarga = async(e) =>{
    try {
      let handle = this.props.match.params.value
      this.setState({isLoaded: true})
      const getDataHousehold = await axios.get(`${API_URL}/households/${handle}`, {headers})
      console.log(getDataHousehold.data)
      console.log(getDataHousehold.data.household.head)
      this.setState({
        household_id:getDataHousehold.data.household.id,
        head_id:getDataHousehold.data.household.head_id,
        kk:getDataHousehold.data.household.family_card_number,
        head_name: getDataHousehold.data.household.head !== null ? getDataHousehold.data.household.head.name : '(Belum Diisi)',
        hamlet_id:getDataHousehold.data.household.hamlet_id,
        village_id:getDataHousehold.data.household.village_id,
        district_id:getDataHousehold.data.household.district_id,
        city_id:getDataHousehold.data.household.city_id,
        province_id:getDataHousehold.data.household.province_id,
        progress:getDataHousehold.data.household.progress
    })
    console.log(this.state.head_name)
    // this.setState({isLoaded: false})
      console.log(this.state.hamlet_id)
      if(this.state.head_id){
        this.setState({isLoaded: false})
      //  return
      }else{
        this.alertFunction()
      }
      this.setState({isLoaded: false})
    } catch(e) {
      this.setState({isLoaded: false})
    }
    
  }

  getDataKeluarga = async(e) =>{
    try {
      this.setState({isLoaded: true})
      const headers = {
        'Authorization' : `Bearer ${localStorage.getItem('access_token')}`,
        'Content-Type' : 'application/json'
      }
      let householdId = this.props.match.params.value
      let getDataHousehold = await axios.get(`${API_URL}/residents?household_id=${householdId}`, {headers})
      this.setState({
          bioproducts:getDataHousehold.data.residents,
      })

      // GET HAMLET
      const getHamlet = await axios.get(`${API_URL}/hamlets/${this.state.hamlet_id}`, {headers})
      console.log(getHamlet)
      this.setState({
        hamlet_name : getHamlet.data.hamlet.name
      })

      // GET VILLAGE
      const getVillage = await axios.get(`${API_URL}/regions/villages?id=${this.state.village_id}`, {headers})
      this.setState({
        village_name : getVillage.data.villages.name
      })

      // GET DISTRICT
      const getDistrict = await axios.get(`${API_URL}/regions/districts?id=${this.state.district_id}`, {headers})
      this.setState({
        district_name : getDistrict.data.districts.name
      })

      // GET CITY
      const getCity = await axios.get(`${API_URL}/regions/cities?id=${this.state.city_id}`, {headers})
      this.setState({
        city_name : getCity.data.cities.name
      })

      // GET PROVINCE
      const getProvince = await axios.get(`${API_URL}/regions/provinces?id=${this.state.province_id}`, {headers})
      this.setState({
        province_name : getProvince.data.provinces.name
        })
        this.setState({isLoaded: false})
      } catch (error) {
        console.log(error);
        this.setState({isLoaded: false})
      }
      
  }  

  getDiedMembers = async() => {
    try {
      this.setState({isLoaded: true})
      const headers = {
        'Authorization' : `Bearer ${localStorage.getItem('access_token')}`,
        'Content-Type' : 'application/json'
      }
      const responseDiedMembers = await axios.get(`${API_URL}/diedMembers?household_id=${this.props.match.params.value}`, {headers})
      let diedMembers = responseDiedMembers.data.diedMembers.map(diedMember => (
        {
          id: diedMember.id,
          name: diedMember.name,
          age: diedMember.age
        }
      ))
      this.setState({
        died_members: diedMembers,
        isLoaded: false
      })
    } catch(e) {
      console.log(e)
      this.setState({isLoaded: false})
    }
  }
   // DELETE HANDLER
   sweetalertfunction(){
    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        swal("Your data has been deleted!", {
          icon: "success",
        });
        this.props.history.push(`/`)
      } else {
        swal("Your data is still save");
      }
    });
  }
  alertFunction = async(e) =>{
    swal({
      title: "Penting!",
      text: "Data kepala keluarga belum lengkap",
      type: "warning",
      html:true,
      buttons:["Nanti", "Lengkapi"]
    })
    .then((move)=>{
      if(move){
        this.props.history.push(`/dataindividu/${this.state.household_id}`)
        // console.log(this.state.household_id)
      }else{
        return
      }
    })
  }

  editHeadHandler = async(e) =>{
    let {head_id} = this.state
    console.log(head_id)
    if(head_id !== null){
      // return <Redirect to={`/editdata/${head_id}`}/>
      this.props.history.push(`/editdata/${head_id}`)
    }else{
      this.alertFunction()
    }
  }
  deleteData = async(e) =>{
      const headers = {
        'Authorization' : `Bearer ${localStorage.getItem('access_token')}`,
        'Content-Type' : 'application/json'
      }
      await axios.delete(`${API_URL}/households/${this.state.household_id}`, {headers})
      this.sweetalertfunction()
  }

  moveHandler = async(e) =>{
    let {household_id} = this.state
      this.props.history.push(`/dataindividu/${household_id}`);
  }
  moveDiedHandler = async(e) =>{
    let {household_id} = this.state
      this.props.history.push(`/dataindividu-died/${household_id}`);
  }

    render(){
      let {kk, head_id, hamlet_name, village_name, district_name, city_name, province_name} = this.state
      const diedMembersColumn = [
        {
          dataField: 'name',
          text: 'Nama'
        },
        {
          dataField: 'age',
          text: 'Umur',
          // style:{textAlign:'left', paddingLeft:'4rem'}
        },
        {
          dataField: 'Aksi',
          text: 'Aksi',
          formatter:diedFormatter
        }
      ]
        return(
          <div>
            <div className={this.state.isLoaded === true ? "loading" : "d-none"}>
              <div className="loaders"></div>
                <h2 className="loading-soal text-center">Sedang memuat...</h2>
            </div>
            <Navbar className="navbar-biodata">
            <Link to={`/`}>
                <FontAwesomeIcon icon={faChevronLeft} className="left-icon" />
              </Link>
              <h5 className="brand-biodata" href="#home">Detail Kepala Keluarga</h5>
              <FontAwesomeIcon icon={faEdit} className="data-edit-icon" onClick={this.editHeadHandler}/>
              <FontAwesomeIcon onClick={this.deleteData} icon={faTrashAlt} className="data-trash-icon" />
              {/* <Dropdown>
                <Dropdown.Toggle>
                  <FontAwesomeIcon icon={faEllipsisV} className="biodata-menu-icon" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="/table" className="item-list">User</Dropdown.Item>
                  <Dropdown.Item href="/table" className="item-list">Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
            </Navbar>
            <div className="biodata-body">
              <div className="bio-wrapper">
                <div className="bio-wrap1">
                  <h1 className="Nokk">No. KK : {kk} </h1>
                </div>
              </div>
              <h2 className="bio-name">{this.state.head_name}</h2>
              <p className="bio-address">{hamlet_name}, {village_name} Kec. {district_name}, {city_name}, {province_name} </p>
              <hr/>
              <h1 className="survei-keluarga">Survei Keluarga</h1>
              <div className="bio-wrapper2">
                <div className="bio-wrap2-1">
                  <ProgressBar now={this.state.progress} label={100} srOnly />
                </div>
                <div className="bio-wrap2-2">
                  <p className="presentase">{this.state.progress}%</p>
                </div>
                <div className="bio-wrap2-3">
                  <Link to={`/household-survey/${this.state.household_id}`}>
                    <button className="bio-start">Mulai</button>
                  </Link>
                </div>
              </div>
              <hr/>
              <div className="bio-wrapper3">
                <div className="bio-wrap3-1">
                  <h1 className="anggota-keluarga">Anggota Keluarga</h1>
                </div>
                <div className="bio-wrap3-2">
                    <button 
                      onClick={this.moveHandler} 
                      className="tambah-baru">
                        <img src={image} className="tambah-baru-icon" alt=""/>Tambah Baru
                      </button>  
                </div>
              </div>
              <BootstrapTable 
                    striped
                    borderless
                    keyField='id' 
                    data={ this.state.bioproducts } 
                    columns={ this.state.biocolumns }/>
              <hr/>
              <div className="bio-wrapper3">
                <div className="bio-wrap3-1">
                  <h2>Anggota Keluarga yang Meninggal</h2>
                </div>
                <div className="bio-wrap3-2">
                    <button 
                      onClick={this.moveDiedHandler} 
                      className="tambah-baru">
                        <img src={image} className="tambah-baru-icon" alt=""/>Tambah Baru
                      </button>  
                </div>
              </div>
              <BootstrapTable 
                    striped
                    borderless
                    keyField='id' 
                    data={ this.state.died_members } 
                    columns={ diedMembersColumn }/>
            </div>
          </div>
        )
    }
}

export default BiodataKeluarga;