import React, { Component } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import Login from "./Components/SignIn/SignIn";
import SignUp from "./Components/SignUp/SignUp";
import HouseholdForm from "./Components/Form/FormHousehold";
import HouseholdForm2 from "./Components/Form/FormHousehold2";
import DetailDataIndividu from "./Components/DetailDataIndividu/DetailData"
import BiodataKeluarga from "./Components/BiodataKeluarga/BiodataKeluarga"
import DataIndividu from "./Components/Form/FormDataIndividu"
import EditDataIndividu from "./Components/Form/FormEditDataIndividu"
import HouseholdPages from "./Components/HouseholdSurvey/HouseholdPages"
import ResidentPages from "./Components/HouseholdSurvey/ResidentSurvey/ResidentPages"
import Table from "./Components/Table/Table"
import LastPage from "./Components/LastPage/LastPage";
import DetailDataDied from "./Components/DetailDataDIed/DetailDataDied";
import DataDied from "./Components/Form/FormDataDied";
import EditDataDied from "./Components/Form/FormEditDataDied";
class App extends Component {
  render() {
    if(localStorage.getItem('access_token') === null){
      window.location.href = '/login'
      localStorage.clear()
      return
    }
    return (
      <div className="App">
         <Router>
          <div>
            <Route path="/login" component={Login} />
            <Route path="/signup" component={SignUp} />
            <Route path="/household-form" component={HouseholdForm} />
            <Route path="/household-form2" component={HouseholdForm2} />
            <Route exact path="/" component={Table} />
            <Route path='/household-survey/:value' component={HouseholdPages}/>
            <Route path='/resident-survey/:value' component={ResidentPages}/>
            <Route path="/selesai" component={LastPage} />
            <Route name="detaildata" path="/detaildata/:value" component={DetailDataIndividu} />
            <Route name="detaildata-died" path="/detaildata-died/:value" component={DetailDataDied} />
            <Route name="editdata" path="/editdata/:value" component={EditDataIndividu} />
            <Route name="biodata" path="/biodatakeluarga/:value" component={BiodataKeluarga} />
            <Route name="dataindividu" path="/dataindividu/:addvalue" component={DataIndividu} />
            <Route name="dataindividu-died" path="/dataindividu-died/:addvalue" component={DataDied} />
            <Route name="editdata-died" path="/editdata-died/:value" component={EditDataDied} />
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
