import React, {Component} from 'react'
import axios from 'axios'
import swal from 'sweetalert';
import {Link} from 'react-router-dom';
import {Navbar, ProgressBar, Table} from 'react-bootstrap'
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faChevronLeft,  faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons'
import '../DetailDataIndividu/DetailData.css'
import {HEADERS, API_URL} from '../BaseUrlApi/Url'

const headers = {
  'Authorization' : `Bearer ${localStorage.getItem('access_token')}`,
  'Content-Type' : 'application/json'
}
class DetailDataDied extends Component{
  constructor(props){
  super(props)
  this.state={
      isLoaded: false,
      household_id:'',
      id:'',
      name:'',
      age:'',
      alert:'',
  }
//   this.deleteData = this.deleteData.bind(this)
}
  componentDidMount(){
    this.getDetailData()
  }

  getDetailData = async(e) =>{
    try {
      this.setState({isLoaded: true})
      const page_value = this.props.match.params
      const getData = await axios.get(`${API_URL}/diedMembers/${page_value.value}`, {headers})
      this.setState({
          household_id: getData.data.diedMember.household.id,
          id: getData.data.diedMember.id,
          name:getData.data.diedMember.name,
        //   birthplace:getData.data.resident.birthplace,
        //   birthdate:getData.data.resident.birthdate,
        //   age:getData.data.resident.age,
        //   gender:getData.data.resident.gender,
        //   religion_id:getData.data.resident.religion_id,
        //   marital_status_id:getData.data.resident.marital_status_id,
        //   education_status_id:getData.data.resident.highest_education_status_id,
        //   job_status_id:getData.data.resident.job_status_id,
        //   progress:getData.data.resident.progress
          age: getData.data.diedMember.age
      })
      // console.log(getData)
      this.setState({isLoaded: false})

    } catch(e) {
      console.log(e)
      this.setState({isLoaded: false})
    }
  }
  deleteData = async(e) =>{
    try{
      await axios.delete(`${API_URL}/diedMembers/${this.props.match.params.value}`, {headers})
      this.props.history.push(`/biodatakeluarga/${this.state.household_id}`)
    } catch(e) {
        console.log(e)
    }
}
    // DELETE HANDLER
    sweetalertfunction = async() => {
    console.log('clicks')
    // return(<SweetAlert title="Here's a message!" onConfirm={this.onConfirm} />)
    swal({
      title: "Apakah anda yakin ingin menghapus data ini?",
      icon: "warning",
      buttons:["Nanti", "Hapus"],
      dangerMode: true,
    })
    .then((move) => {
        
      if (move) {
        // swal("Data berhasil terhapus!", {
        //   icon: "success",
        // });
        this.deleteData()
        
      } else {
        // swal("");
        return
      }
    }); 
  }

  render(){
    const {household_id, id} = this.state
      return(
          <div className="detai-data-page">
            <div className={this.state.isLoaded === true ? "loading" : "d-none"}>
              <div className="loaders"></div>
                <h2 className="loading-soal text-center">Sedang memuat...</h2>
            </div>
          <Navbar className="navbar-kk">
            <Link to={`/biodatakeluarga/${household_id}`}>
              <FontAwesomeIcon icon={faChevronLeft} className="left-icon" />
            </Link>
            <h5 className="brand-kk" href="#home">Detail Data Individu yang meninggal</h5>
            <Link to={`/editdata-died/${id}`}>
              <FontAwesomeIcon icon={faEdit} className="data-edit-icon" />
            </Link>
            <FontAwesomeIcon onClick={this.sweetalertfunction} icon={faTrashAlt} className="data-trash-icon" />
          </Navbar>
          <div className="custom-table">
          <Table striped bordered hover className="data-table">
              <tbody>
                <tr>
                  <td>Nama</td>
                  <td>{this.state.name}</td>
                </tr>
                <tr>
                  <td>Umur</td>
                  <td>{this.state.age}</td>
                </tr>
              </tbody>
          </Table>
          </div>
    </div>
    )
  }
}

export default DetailDataDied