import React, {Component} from 'react'
import './StringChoice.css'

class StringChoice extends Component{
  constructor(props){
    super(props)
    this.state = {
      string:''
    }
  }
  componentDidMount(){
  }

  changeHandler = async(e) =>{
    let stringAnswer = {
      question_id : parseInt(e.target.name),
      string_answer : e.target.value
    }
    this.props.handler(stringAnswer)
  }

  fillString = string_id =>{
    let value = ''
    this.props.strings.map(string => {
      if(string.question_id === string_id){
        value = string.string_answer
        return value
      }
    })
    return value
  }
  render(){
    return(
      <div className="string-question">
        <p className="question-text">
          {this.props.questions.question}
        </p>
        <div className="container string-wrap">
          <input 
          className="input" 
          value={this.fillString(this.props.questions.id)}
          id={this.props.questions.id}
          name={this.props.questions.id}
          type="text"
          onChange={this.changeHandler}
          />
          <span className="border"></span>
        </div>
        </div>
    )
  }
}

export default StringChoice